import { RiEditLine, RiListSettingsLine } from '@remixicon/react';
import { TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DeleteClassifierRecord } from 'features/classifiers/ClassifierRecords/DeleteClassifierRecord';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { useClassifierAttributesDeclarations } from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import { useClassifierItems } from 'entities/classifiers/classifiersItems/classifierItems.model';
import { setCurrentClassifierItem } from 'entities/classifiers/classifiersItems/classifierItems.store';
import { ClassifierItemResponse } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { useAppDispatch, useAppSelector, usePagination } from 'shared/hooks';
import { Chip, DropdownLink } from 'shared/ui';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import ItemActions from 'shared/ui/components/ItemActions';

type ItemValuesType = string | number | boolean;

export type ICatalogRecord = {
	id: string;
	recStatus: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;

export interface IRecordValue {
	[key: string]: {
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

export const useClassifierColumns = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { classifierGroupId, classifierItemId } = useParams();
	const { pagination, handlePaginationChange, toTablePaginationProps } = usePagination();

	const { classifierAttributeDeclarations, loading } = useClassifierAttributesDeclarations();
	const {
		classifiersList,
		selectedClassifier,
		getClassifiers,
		loading: tableLoading,
		classifierItemsTotal,
		getGroups,
	} = useClassifierItems();

	const [columns, setColumns] = useState<TableProps<any>['columns']>(null);
	const [dataSource, setDataSource] = useState(null);

	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	const convertValues = (values: IRecordValue): IMappedRecordValue =>
		Object.fromEntries(
			classifierAttributeDeclarations?.map((attributeDeclaration) => {
				const { id, attribute } = attributeDeclaration;

				return [id, CellValueParser(values[id]?.value, attribute.type as CellTypesEnum)];
			}) ?? []
		);

	const mapRecordsDtoToTable = (recordsDto: ClassifierItemResponse[] | null): ICatalogRecords => {
		if (!recordsDto) {
			return null;
		}
		return recordsDto.map((item) => ({
			id: item.id,
			key: item.id,
			recStatus: (
				<Chip status={item.status.toLowerCase() as ChipStatus}>
					{chipTitle(item.status)}
				</Chip>
			),
			menu: (
				<ItemActions
					items={[
						{
							key: `${item?.id}-${randomString(4)}`,
							label: <DeleteClassifierRecord recordId={item.id} />,
						},
						{
							key: `${item?.id}-${randomString(4)}`,
							label: (
								<DropdownLink
									icon={<RiEditLine size={16} />}
									title="Редактировать"
									callback={() => {
										dispatch(setCurrentClassifierItem(item));
										navigate(`${item.id}/${routes.classifiers.edit}`);
									}}
								/>
							),
						},
					]}
				/>
			),
			...(item.values && convertValues(item.values as unknown as IRecordValue)),
		}));
	};

	useEffect(() => {
		if (!pagination) return;

		getClassifiers(pagination.current, pagination.pageSize, classifierItemId).then();
	}, [classifierItemId, pagination]);

	useEffect(() => {
		if (classifiersList && (classifierItemId == null || selectedClassifier)) {
			setDataSource(
				mapRecordsDtoToTable([
					...(selectedClassifier ? [selectedClassifier] : []),
					...classifiersList,
				])
			);
		}
	}, [selectedClassifier, classifiersList]);

	useEffect(() => {
		if (classifierAttributeDeclarations) {
			const newColumns = [
				{
					title: 'Статус',
					dataIndex: 'recStatus',
					key: 'recStatus',
				},
				...classifierAttributeDeclarations.map((decl) => ({
					key: decl.id,
					dataIndex: decl.id,
					title: decl.attribute.displayName,
				})),
				{
					title: <RiListSettingsLine size={20} />,
					dataIndex: 'menu',
					key: 'menu',
					width: '50px',
				},
			];
			setColumns(newColumns);
		}
	}, [classifierAttributeDeclarations]);

	useEffect(() => {
		getGroups();
	}, [transactionRollback]);

	return {
		columns,
		dataSource,
		handleTableChange: handlePaginationChange,
		pagination: toTablePaginationProps(classifierItemsTotal),
		loading,
		tableLoading,
		classifierGroupId,
	};
};
