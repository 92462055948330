import { Flex, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CatalogGroupTreeWidget } from 'widgets/Catalogs/CatalogGroupTreeWidget';
import { CatalogItemsCalendarWidget } from 'widgets/Catalogs/CatalogItemsCalendarWidget/catalogItemsCalendarWidget.ui';
import { CatalogItemsGanttWidget } from 'widgets/Catalogs/CatalogItemsGanttWidget/catalogItemsGanttWidget.ui';
import { CatalogItemsTableWidget } from 'widgets/Catalogs/CatalogItemsTableWidget';
import { Transactions } from 'widgets/Transactions';
import { AddCatalogRecordUi } from 'features/catalogs/CatalogRecords/AddCatalogRecord';
import { ExportCatalogRecordUi } from 'features/catalogs/CatalogRecords/ExportCatalogRecord';
import { ToggleSubordinatedCatalogsRecords } from 'features/catalogs/CatalogRecords/ToggleSubordinatedCatalogsRecords';
import {
	ChangeCatalogItemsViewType,
	useCatalogItemsViewType,
} from 'features/catalogs/ChangeCatalogItemsViewType';
import { ImportCatalogRecord } from 'features/catalogs/importCatalogRecord';
import { CatalogItemsViewType } from 'entities/catalogs';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { renderContent, RenderElementType } from 'shared/helpers/renderContent';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';
import { Search, setSubMenuCollapsed } from 'shared/ui';
import { SubSider } from 'shared/ui/components';

const CatalogPage: React.FC = () => {
	const { t } = useTypedTranslation();

	const dispatch = useAppDispatch();

	const { catalogGroupId } = useParams();
	const [ searchParams, setSearchParams ] = useSearchParams();

	const defaultValue = searchParams.get('searchValue');

	const catalogRecordsViewType = useCatalogItemsViewType();
	const { catalogList, loading: catalogListLoading, selectedCatalog } = useCatalogGroups();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		searchParams.set('searchValue', e.target.value);
		setSearchParams(searchParams);
	}, 1000);

	const [ searchValue, setSearchValue ] = useState<string>('');
	const [ isSubordinatedCatalogsRecordsShown, setIsSubordinatedCatalogsRecordsShown ] = useState<boolean | undefined>();

	const isCatalogGroupsEmpty = !catalogList?.length && !selectedCatalog && !catalogListLoading;
	const isCatalogNotSelected =
		!catalogGroupId ||
		(catalogList?.length !== 0 && !selectedCatalog && !catalogListLoading);

	const searchInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		searchHandler(e);
	};

	useEffect(() => {
		setSearchValue('');
	}, [ catalogGroupId ]);

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	const contentFactory = (): RenderElementType => {
		if (isCatalogGroupsEmpty) {
			return {
				placeholder: {
					title: t((l) => l.catalogs.groups.noGroups),
				},
			};
		} else if (isCatalogNotSelected) {
			return {
				placeholder: {
					title: t((l) => l.catalogs.groups.selectGroup),
				},
			};
		} else if (selectedCatalog) {
			if (catalogRecordsViewType == CatalogItemsViewType.Table) {
				return {
					content: (
						<CatalogItemsTableWidget catalogId={catalogGroupId}
												 isSubordinatedCatalogsRecordsShown={isSubordinatedCatalogsRecordsShown}
						/>
					),
				};
			} else if (catalogRecordsViewType == CatalogItemsViewType.Gantt) {
				return {
					content: (
						<CatalogItemsGanttWidget catalogId={catalogGroupId}
												 isSubordinatedCatalogsRecordsShown={isSubordinatedCatalogsRecordsShown}
						/>
					),
				};
			} else if (catalogRecordsViewType == CatalogItemsViewType.Calendar) {
				return {
					content: (
						<CatalogItemsCalendarWidget catalogId={catalogGroupId}
													isSubordinatedCatalogsRecordsShown={isSubordinatedCatalogsRecordsShown}
						/>
					),
				};
			}
		}
	};

	return (
		<>
			<SubSider>
				<CatalogGroupTreeWidget/>
			</SubSider>

			<Flex vertical gap={24} style={{ height: 'calc(100vh - 116px)' }}>
				<Flex justify="space-between" align="top">
					<Typography.Title level={1}>
						{selectedCatalog && selectedCatalog.displayName}
					</Typography.Title>

					<Transactions/>
				</Flex>

				{(selectedCatalog && catalogGroupId) && (
					<>
						<Search
							onChange={searchInputHandler}
							defaultValue={defaultValue}
							value={searchValue}
						/>
						<Flex gap={4} align="center">
							<AddCatalogRecordUi/>
							<ImportCatalogRecord/>
							<ExportCatalogRecordUi catalogId={catalogGroupId}/>
							<Flex gap={8} align="center" style={{ marginLeft: 'auto' }}>
								<ToggleSubordinatedCatalogsRecords onChange={setIsSubordinatedCatalogsRecordsShown}/>

								<ChangeCatalogItemsViewType catalogId={catalogGroupId}/>
							</Flex>
						</Flex>
					</>
				)}

				<div style={{ height: '100%' }}>{renderContent(contentFactory)}</div>
			</Flex>
		</>
	);
};

export {
	CatalogPage,
};