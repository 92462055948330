import { useEffect } from 'react';
import {
	transactionServiceApi,
	useGetTransactionsDataQuery,
} from 'shared/api/generatedApi/transactionServiceApi';
import { useAppDispatch, useAppSelector, useHandleQueryError } from 'shared/hooks';
import { setTransactionsStore } from './transactions.store';

export type transactionStatusType = 'ACTIVE' | 'PAUSED' | 'COMMITED' | 'COMMIT_ERROR';

export const useTransactions = () => {
	const dispatch = useAppDispatch();

	const userId = useAppSelector((state) => state.entities.session.userId);

	const { data: transactions, isLoading: transactionsLoading, error: transactionsError } = useHandleQueryError(
		useGetTransactionsDataQuery({
			userId,
			full: false,
			status: [ 'ACTIVE', 'PAUSED' ],
		}),
		t => t.transactions.errorGetList);

	useEffect(() => {
		if (transactions) {
			dispatch(setTransactionsStore(transactions.map(x => x.transaction)));
		}
	}, [ transactions ]);

	const getTransactions = async () => {
		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
	};

	return {
		transactions,
		transactionsError,
		transactionsLoading,
		getTransactions,
	};
};
