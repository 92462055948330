import { RiDatabase2Line, RiNodeTree, RiListView, RiShareForwardBoxLine, RiDraftLine, RiPieChartLine } from '@remixicon/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from './routes';

export const navItems = [
	// {
	// 	label: <NavLink to={routes.main.main}>Главная</NavLink>,
	// 	title: '',
	// 	icon: <RiHome3Line size={16} />,
	// },
	{
		label: <NavLink to={routes.catalogs.main}>Справочники</NavLink>,
		title: 'catalogs',
		icon: <RiNodeTree size={16} />,
	},
	{
		label: <NavLink to={routes.classifiers.main}>Классификаторы</NavLink>,
		title: 'classifiers',
		icon: <RiListView size={16} />,
	},
	{
		label: <NavLink to={routes.metadata.main}>Метаданные</NavLink>,
		title: 'metadata',
		icon: <RiDatabase2Line size={16} />,
	},
	{
		label: <NavLink to={routes.integrations.main}>Интеграции</NavLink>,
		title: 'integrations',
		icon: <RiShareForwardBoxLine size={16} />,
	},
	{
		label: <NavLink to={routes.applications.main}>Заявки</NavLink>,
		title: 'applications',
		icon: <RiDraftLine size={16} />,
	},
	{
		label: <NavLink to={routes.dataQuality.main}>Отчеты</NavLink>,
		title: 'dataQuality',
		icon: <RiPieChartLine size={16} />,
	}
	// {
	// 	label: (
	// 		<NavLink
	// 			to={routes.settings.main}
	// 			onClick={() => window.location.assign(routes.settings.main)}
	// 		>
	// 			Настройки
	// 		</NavLink>
	// 	),
	// 	title: 'settings',
	// 	icon: <RiSettings4Line size={16} />,
	// },
];
