import { Card } from 'antd';
import keycloak from 'keycloak';
import type { KeycloakProfile } from 'keycloak-js';
import React from 'react';

interface UserProfileDebugInfoProps {
    userInfo: KeycloakProfile;
}

export function UserProfileDebugInfo({ userInfo }: UserProfileDebugInfoProps) {
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (!isDevelopment) {
        return null;
    }

    return (
        <Card title="Данные профиля (JSON)">
            <pre style={{ 
                background: '#f5f5f5',
                padding: '16px',
                borderRadius: '4px',
                overflow: 'auto'
            }}>
                {JSON.stringify({
                    profile: userInfo,
                    tokenParsed: keycloak.tokenParsed
                }, null, 2)}
            </pre>
        </Card>
    );
}
