import { App } from 'antd';
import { useParams } from 'react-router';
import { useImportXlsxMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';

export const useImportCatalogRecord = () => {
	const { notification } = App.useApp();

	const [ importRecord, { isLoading } ] = useImportXlsxMutation();
	const { catalogGroupId } = useParams();

	const handleImport = async (base64: string): Promise<boolean> => {
		try {
			await importRecord({
				catalogId: catalogGroupId,
				xlsxImportCatalogItemsRequest: { content: base64 },
			})
				.unwrap();
			return true;
		} catch (e) {
			errorHelper('Ошибка при импорте записи!', e, notification);
			return false;
		}
	};

	return {
		handleImport,
		isLoading: isLoading,
	};
};
