import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogAttributeDeclarationResponse } from 'shared/api/generatedApi/mdmgApi';

interface IAttributesState {
	attributeDeclarationsList: Array<CatalogAttributeDeclarationResponse>;
}

const initialState: IAttributesState = {
	attributeDeclarationsList: null,
};

const attributesSlice = createSlice({
	name: 'attributes',
	initialState,
	reducers: {
		setAttributeDeclarationsList(
			state,
			{ payload }: PayloadAction<Array<CatalogAttributeDeclarationResponse>>
		) {
			state.attributeDeclarationsList = payload;
		},
		editAttributeDeclaration(state, { payload }: PayloadAction<CatalogAttributeDeclarationResponse>) {
			state.attributeDeclarationsList = state.attributeDeclarationsList.map((item) => {
				if (item.id === payload.id) return payload;
				return item;
			});
		},
		addNewAttributeDeclaration(state, { payload }: PayloadAction<CatalogAttributeDeclarationResponse>) {
			if (!state.attributeDeclarationsList.find((item) => item.id === payload.id)) {
				state.attributeDeclarationsList = [...state.attributeDeclarationsList, payload];
			}
		},
		deleteAttributeDeclaration(state, { payload }: PayloadAction<string>) {
			state.attributeDeclarationsList = state?.attributeDeclarationsList?.filter(
				(catalog) => catalog.id !== payload
			);
		},
		removeAttributeDeclarationList(state) {
			state.attributeDeclarationsList = null;
		},
	},
});

export const {
	setAttributeDeclarationsList,
	editAttributeDeclaration,
	addNewAttributeDeclaration,
	deleteAttributeDeclaration,
	removeAttributeDeclarationList,
} = attributesSlice.actions;

export const attributeDeclarationsListReducer = attributesSlice.reducer;
