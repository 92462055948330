import { App } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { useGetCurrentMeasureUnit, setCurrentMeasureUnit } from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { useUpdateMeasurementMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const useEditMeasureUnit = () => {
	const [editUnits] = useUpdateMeasurementMutation();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();
	const { currentMeasureUnit } = useGetCurrentMeasureUnit();
	const { t } = useTypedTranslation();

	const { getTransactions } = useTransactions();

	const editInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
		let value;
		switch (e.target.id) {
			case 'name':
				value = {
					displayName: e.target.value,
				};
				break;
			case 'code':
				value = {
					shortName: e.target.value,
				};
				break;
			case 'factor':
				value = {
					coefficient: +e.target.value,
				};
				break;
			case 'formula':
				value = {
					formula: e.target.value,
				};
				break;
			case 'revFormula':
				value = {
					inverseFormula: e.target.value,
				};
				break;
		}

		await editUnits({
			measurementGroupId: currentMeasureUnit.measurementGroupId,
			id: currentMeasureUnit.id,
			updateMeasurementRequest: {
				...currentMeasureUnit,
				...value,
			},
		})
			.then((res) => {
				if ('data' in res) {
					dispatch(setCurrentMeasureUnit({ ...currentMeasureUnit, ...value }));
				}
				if ('error' in res) {
					dispatch(setCurrentMeasureUnit({ ...currentMeasureUnit }));
					errorHelper(
						t((l) => l.measures.error.editingMeasurmentUnitError),
						res.error,
						notification
					);
				}
				getTransactions();
			})
			.catch((e) => {
				dispatch(setCurrentMeasureUnit(currentMeasureUnit));
				errorHelper(
					t((l) => l.measures.error.editingMeasurmentUnitError),
					e,
					notification
				);
			});
	};

	const editBasic = async (e: CheckboxChangeEvent) => {
		await editUnits({
			measurementGroupId: currentMeasureUnit.measurementGroupId,
			id: currentMeasureUnit.id,
			updateMeasurementRequest: {
				isDefault: e.target.checked,
				displayName: currentMeasureUnit.displayName,
			},
		})
			.then((res) => {
				if ('data' in res) {
					dispatch(
						setCurrentMeasureUnit({
							...currentMeasureUnit,
							isDefault: e.target.checked,
						})
					);
				}
				if ('error' in res) {
					dispatch(setCurrentMeasureUnit({ ...currentMeasureUnit }));

					if (res.error && typeof res.error === 'object' && 'data' in res.error) {
						const errorData = res.error.data as {
							blockedEvents: { notification: string }[];
						};

						if (errorData.blockedEvents && errorData.blockedEvents.length > 0) {
							errorHelper(
								t((l) => l.measures.error.designatingSecondBaseUnitError),
								new Error(`${errorData.blockedEvents[0].notification}`),
								notification
							);
						}
					}
				}
				getTransactions();
			})
			.catch((e) => {
				errorHelper(
					t((l) => l.measures.error.designatingBaseUnitError),
					e,
					notification
				);
				dispatch(setCurrentMeasureUnit({ ...currentMeasureUnit, isDefault: false }));
			});
	};

	return {
		editInput,
		editBasic,
	};
};
