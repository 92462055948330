import { RiSettings3Line } from '@remixicon/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/config/navigation/routes';
import { useTypedTranslation } from 'shared/hooks';
import { styles } from './GoToUserProfileButton.styles';

export function GoToUserProfileButton() {
    const navigate = useNavigate();
    const { t } = useTypedTranslation();

    const handleClick = () => {
        navigate(routes.profile.main);
    };

    return (
        <a onClick={handleClick} style={styles}>
            <RiSettings3Line />
            {t(l => l.profile.goToProfileButton)}
        </a>
    );
}
