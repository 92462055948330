import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ItemStatusDto } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { setClassifierAttributeDeclarationsList } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import { useClassifierAttributesDeclarations } from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import { useTransactions } from 'entities/transactions';
import {
	ClassifierItemResponse,
	useLazyGetClassifierItemQuery,
	useUpdateClassifierItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { ItemValuesType, RecordType, Values } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';
import { ChipStatusEnum } from 'shared/ui/components/Chip/chipStylehelper';
import { EditRecordStatus } from '../EditRecordStatus/EditRecordStatus';

const updateValue = (values: any, update?: { key: string; value: any }) => {
	return Object.fromEntries(
		Object.entries(values as Values).map(([key, { value }]) => [
			key,
			(key == update?.key ? update.value : value) as object,
		])
	);
};

export const useEditCatalogRecord = () => {
	const [recordsList, setRecordsList] = useState<RecordType[]>([]);
	const [record, setRecord] = useState<ClassifierItemResponse | null>(null);
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const {
		classifierAttributeDeclarations,
		getAttributes,
		loading: isLoading,
	} = useClassifierAttributesDeclarations();

	const [getRecords, { error: recordsError, isFetching: isRecordsLoading }] =
		useLazyGetClassifierItemQuery();
	const [fetchUpdateItem] = useUpdateClassifierItemMutation();

	const { classifierGroupId, classifierItemId } = useParams();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const mapValues = (data?: ClassifierItemResponse) => {
		const editItem = (declarationId: string, newValue: ItemValuesType) => {
			updateItem(
				updateValue(data.values, { key: declarationId, value: newValue }),
				data.status,
				data.parentItemId
			).then(() => {
				setRecord((prev) => ({
					...prev,
					values: {
						...prev.values,
						[declarationId]: {
							...prev.values[declarationId],
							newValue,
						},
					},
				}));
			});
		};

		const onStatusChange = (status: ChipStatusEnum) => {
			const itemStatus = status.toUpperCase() as ItemStatusDto;
			updateItem(updateValue(data.values), itemStatus, data.parentItemId).then(() => {
				setRecord((prev) => ({
					...prev,
					status: itemStatus,
				}));
			});
		};

		const flattenedRecords: RecordType[] = Object.keys(data.values as unknown as Values).map(
			(key) => {
				const record = (data.values as unknown as Values)[key];

				return {
					id: key,
					attributeName: record?.attributeName,
					value: CellInputParser(
						record?.value,
						classifierAttributeDeclarations?.find(
							(declaration) => declaration.id === key
						),
						editItem
					),
					key: key,
				};
			}
		);

		flattenedRecords.unshift({
			attributeName: 'Статус позиции',
			id: 'status',
			key: 'status',
			value: <EditRecordStatus data={data} onChange={onStatusChange} />,
		});
		setRecordsList(flattenedRecords);
	};

	const updateItem = async (
		values: { [key: string]: object },
		status: ItemStatusDto,
		parentId: string | null
	) => {
		await fetchUpdateItem({
			id: classifierItemId,
			classifierId: classifierGroupId,
			updateClassifierItemRequest: {
				parentItemId: parentId,
				status,
				values,
			},
		})
			.unwrap()
			.then(() => {
				getTransactions();
			})
			.catch((err) => {
				errorHelper('Ошибка при редактировании записи', err, notification);
			});
	};

	useEffect(() => {
		if (record) {
			mapValues(record);
		}
	}, [record]);

	useEffect(() => {
		if (!classifierAttributeDeclarations) {
			getAttributes(classifierGroupId)
				.then((res) => {
					if (Array.isArray(res)) {
						dispatch(setClassifierAttributeDeclarationsList(res));
					} else {
						errorHelper(
							'Ошибка при получении деклараций атрибутов классификатора',
							res,
							notification
						);
					}
				})
				.catch((err) => {
					errorHelper(
						'Ошибка при получении деклараций атрибутов классификатора',
						err,
						notification
					);
				});
		} else {
			const getData = async () => {
				const data = await getRecords({ id: classifierItemId });
				if ('error' in data) {
					errorHelper('Ошибка при получении списка атрибутов', data.error, notification);
				}
				if ('data' in data) {
					setRecord(data.data);
				}
			};

			getData();
		}
	}, [classifierAttributeDeclarations]);

	useEffect(() => {
		if (!recordsError) {
			setError(null);
		}
	}, [recordsError]);

	return {
		recordsList,
		attributeDeclarations: classifierAttributeDeclarations,
		loading: isRecordsLoading || isLoading,
		error,
	};
};
