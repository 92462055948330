import { Flex, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from 'shared/hooks';

export const ToggleSubordinatedCatalogsRecords = ({ loading, onChange } : any) => {
	const { pathname } = useLocation();

	const currentCatalog = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups.currentCatalogGroup
	);
	const disabled = !currentCatalog?.parent;

	const [isSubordinatedCatalogsRecordsShown, setIsSubordinatedCatalogsRecordsShown] =
		useState<boolean>(
			JSON.parse(localStorage.getItem(pathname))
				?.isSubordinatedCatalogsRecordsShown
		);

	useEffect(() => {
		setIsSubordinatedCatalogsRecordsShown(
			JSON.parse(localStorage.getItem(pathname))?.isSubordinatedCatalogsRecordsShown
		);
	}, [ pathname ]);

	useEffect(() => {
		onChange(isSubordinatedCatalogsRecordsShown);

		localStorage.setItem(
			pathname,
			JSON.stringify({
				...JSON.parse(localStorage.getItem(pathname)),
				isSubordinatedCatalogsRecordsShown: isSubordinatedCatalogsRecordsShown,
			})
		);
	}, [ isSubordinatedCatalogsRecordsShown ]);

	const toggleSubordinatedCatalogsRecords = () => {
		setIsSubordinatedCatalogsRecordsShown(prev => !prev);
	};

	return (
		<Flex gap={4}>
			<Switch
				size="small"
				checked={isSubordinatedCatalogsRecordsShown}
				disabled={disabled}
				onChange={toggleSubordinatedCatalogsRecords}
				loading={loading}
			/>
			<Typography.Text disabled={disabled}>
				Отображать позиции подчиненных групп
			</Typography.Text>
		</Flex>
	);
};
