import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiCheckLine } from '@remixicon/react';
import { Result, Spin, Form, Input, Flex, App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TaskType, RealtimeBaseEvent, useRealtimeEvent } from 'entities/events';
import {
	CatalogResponse, mdmgApi,
	UpdateCatalogRequest,
	useGetCatalogQuery,
	useUpdateCatalogAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';

interface IEditCatalogMainInfoProps {
	selectedCatalog: CatalogResponse;
	loading: boolean;
	error: FetchBaseQueryError | SerializedError;
	isDropdownLink?: boolean;
	displayNameAttributeDeclarationId?: string;
}

const EditCatalogMainInfoUi: React.FC<IEditCatalogMainInfoProps> = ({
																		selectedCatalog,
																		loading,
																		error,
																		isDropdownLink,
																		displayNameAttributeDeclarationId,
																	}) => {
	const { notification } = App.useApp();
	const dispatch = useDispatch();

	const [ form ] = useForm();
	const catalogId = selectedCatalog?.id;

	const [ updateCatalogInfo ] = useUpdateCatalogAsyncMutation();

	const { data: currentCatalog } = useGetCatalogQuery({
		id: catalogId,
	}, {
		skip: !catalogId,
	});

	useRealtimeEvent(TaskType.UPDATE_CATALOG_BACKGROUND_TASK, ({ payload }: RealtimeBaseEvent) => {
		if (payload.eventType === 'UPDATE' &&
			payload.entityType === 'CATALOG' &&
			(payload.entityIds as string[]).includes(currentCatalog.id)) {

			dispatch(mdmgApi.util.invalidateTags([ 'Catalog' ]));
			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		}
	}, [ currentCatalog ]);

	const handleEditInfo = async (body: UpdateCatalogRequest, currentCatalog: CatalogResponse) => {
		if (!body.displayName) {
			errorHelper('Поле Наименование должно быть заполнено!', new Error(''), notification);
			return;
		}
		const data = await updateCatalogInfo({
			id: currentCatalog.id,
			updateCatalogRequest: {
				displayName: body.displayName,
				description: body.description,
				parentId: currentCatalog.parentId,
				displayNameAttributeDeclarationId: body.displayNameAttributeDeclarationId,
			},
		});
		if ('error' in data) {
			errorHelper('Ошибка при редактировании справочника!', data.error, notification);
		}
	};

	const handleBlur = async () => {
		const vals = form.getFieldsValue();
		if (
			vals.displayName === selectedCatalog?.displayName &&
			vals.description === selectedCatalog?.description
		) {
			return;
		}

		vals.displayNameAttributeDeclarationId = selectedCatalog?.displayNameAttributeDeclarationId
			? selectedCatalog?.displayNameAttributeDeclarationId
			: null;
		await handleEditInfo(vals, selectedCatalog);
	};

	const dropdownLinkHandler = async (id: string) => {
		const body = {
			displayName: selectedCatalog?.displayName,
			description: selectedCatalog?.description,
			id: selectedCatalog?.id,
			displayNameAttributeDeclarationId: id,
		};

		await handleEditInfo(body, selectedCatalog);
	};

	useEffect(() => {
		if (currentCatalog) {
			form.setFieldsValue(currentCatalog);
		}
	}, [ currentCatalog ]);

	if (error) {
		return (
			<Result
				status="error"
				title="Ошибка при получении данных для справочника!"
				subTitle={`${error}`}
			/>
		);
	}

	return isDropdownLink ? (
		<DropdownLink
			title="Установить, как название записи"
			icon={<RiCheckLine size={16}/>}
			callback={() => dropdownLinkHandler(displayNameAttributeDeclarationId)}
		/>
	) : (
		<div>
			{loading && (
				<Flex justify="center" align="center">
					<Spin size="large"/>
				</Flex>
			)}
			{!loading && (
				<Form layout="vertical" form={form}>
					<Form.Item label="Наименование" required name="displayName">
						<Input onBlur={handleBlur}/>
					</Form.Item>
					<Form.Item label="Описание" name="description">
						<Input onBlur={handleBlur}/>
					</Form.Item>
				</Form>
			)}
		</div>
	);
};

export const EditCatalogMainInfo = React.memo(EditCatalogMainInfoUi);
