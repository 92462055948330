import { RiDeleteBinLine } from '@remixicon/react';
import { Button, Flex, Form, Skeleton, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useConstraintTableRow } from 'entities/metadata/constraintTables';
import { AttributeDto } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { ItemValuesType } from 'shared/helpers/types';
import { DetailFooter } from 'shared/ui';
import { DeleteRowModal } from '../deleteRow';
import { EditRelationAttribute } from '../editRelationAttribute';
import { useEditConstraintTableRow } from './editConstraintTableRow.model';
import { flexStyle } from './EditConstraintTableRowStyles';

export const EditConstraintTableRow: React.FC = () => {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const { isLoading, tableColumns, currentRow } = useConstraintTableRow();

	const { editInfo, attributeLoading } = useEditConstraintTableRow();

	const { constraintTableId, constraintTableRowId } = useParams();

	const navigate = useNavigate();

	const goToConstraintTablePage = () => {
		navigate(
			`/${routes.metadata.main}/${routes.metadata.constraintTables}/${constraintTableId}`
		);
	};

	const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
	const handleDeleteModalOk = () => {
		setIsDeleteModalOpen(false);
		goToConstraintTablePage();
	};
	const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);

	const handleRenderInput = (value: ItemValuesType, attributeDeclaration: AttributeDto) => {
		if (attributeDeclaration.type === CellTypesEnum.RELATION) {
			return (
				<EditRelationAttribute
					key={attributeDeclaration.id}
					attributeDeclaration={attributeDeclaration}
					editInfo={
						editInfo as unknown as (
							attributeId: string,
							newValue: ItemValuesType[]
						) => Promise<void>
					}
					value={value as unknown as ItemValuesType[]}
				/>
			);
		}

		const valuesArray = Array.isArray(value) ? value.flat(Infinity).filter(Boolean) : [value];

		const valuesWithEmpty = valuesArray[0] !== null ? [...valuesArray, ''] : [...valuesArray];

		return CellInputParser(valuesWithEmpty as ItemValuesType, attributeDeclaration, editInfo);
	};

	useEffect(() => {
		if (currentRow === undefined) goToConstraintTablePage();
	}, [currentRow]);

	return (
		<>
			<Flex vertical gap={12} style={flexStyle}>
				<Typography.Title level={2}>Общая информация</Typography.Title>

				{isLoading ? (
					<Skeleton.Input active block />
				) : (
					tableColumns &&
					tableColumns?.length > 0 && (
						<Form layout="vertical">
							{tableColumns?.map((col) => {
								if (currentRow && Object.keys(currentRow).length > 0) {
									return (
										<Fragment key={randomString(6)}>
											<Form.Item label={col.displayName}>
												{attributeLoading === col.id ? (
													<Skeleton.Input active block />
												) : (
													handleRenderInput(
														currentRow[col.id] as ItemValuesType,
														col
													)
												)}
											</Form.Item>
										</Fragment>
									);
								} else return <Skeleton.Input active block />;
							})}
						</Form>
					)
				)}
			</Flex>
			<DetailFooter customHandleBack={goToConstraintTablePage}>
				<Button
					type="text"
					onClick={handleDeleteModalOpen}
					icon={<RiDeleteBinLine size={18} />}
				>
					Удалить
				</Button>
			</DetailFooter>

			<DeleteRowModal
				onOk={handleDeleteModalOk}
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				rowsToDelete={[constraintTableRowId]}
			/>
		</>
	);
};
