import { RiEditLine, RiListSettingsLine } from '@remixicon/react';
import { Flex, Result, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CreateMeasureUnit } from 'features/metadata/Measures/createMeasureUnit';
import { DeleteMeasureUnit } from 'features/metadata/Measures/deleteMeasureUnit';
import {
	SearchMeasureUnit,
	useSearchMeasureUnit,
} from 'features/metadata/Measures/searchMeasureUnit';
import { useGetCurrentMeausureGroup } from 'entities/metadata/measures';
import { routes } from 'shared/config';
import { useAppSelector, useTypedTranslation } from 'shared/hooks';
import { DetailFooter, DropdownLink, WorkTable } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';

interface DataType {
	id: string;
	key: string;
	name: string;
	code: string;
	factor: string;
	formula: string;
	isBasic: string;
}

const DetailMeasuresGroupWidget = () => {
	const [dataSource, setDataSource] = useState<DataType[]>(null);
	const [selectedRows, setSelectedRows] = useState<DataType[]>(null);
	const { currentMeasureGroup, loading, error, getData } = useGetCurrentMeausureGroup();
	const { isLoading: isSearchLoading } = useSearchMeasureUnit();
	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const customCallback = () => navigate(`/${routes.metadata.main}/${routes.metadata.measures}`);
	const columns: TableProps<DataType>['columns'] = [
		{
			title: t((l) => l.measures.unit.displayName),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t((l) => l.measures.unit.shortName),
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: t((l) => l.measures.unit.coefficient),
			dataIndex: 'factor',
			key: 'factor',
		},
		{
			title: t((l) => l.measures.unit.basicUnit),
			dataIndex: 'isBasic',
			key: 'isBasic',
		},
		{
			title: <RiListSettingsLine size={16} />,
			key: 'action',
			width: 16,
			render: (_, record) => (
				<ItemActions
					items={[
						{
							label: (
								<DropdownLink
									title={t((l) => l.common.buttons.edit)}
									icon={<RiEditLine size={16} />}
									callback={function (): void {
										navigate(`unit/${record.id}`);
									}}
								/>
							),
							key: '1',
						},
						{
							label: <DeleteMeasureUnit isDropdown unitId={[record.id]} />,
							key: '2',
						},
					]}
					placement="bottomRight"
				/>
			),
		},
	];

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			setSelectedRows(selectedRows);
		},
	};

	useEffect(() => {
		if (currentMeasureGroup) {
			setSelectedRows(null);
			setDataSource(
				currentMeasureGroup?.childrenMeasures?.map((unit) => ({
					id: unit.id,
					name: unit.displayName,
					code: unit.shortName,
					factor: unit.isDefault
						? '1'
						: unit.coefficient
							? `${unit.coefficient}`
							: t((l) => l.measures.unit.noCoefficient),
					formula: unit.formula,
					revFormula: unit.inverseFormula,
					isBasic: unit.isDefault
						? t((l) => l.measures.unit.isBasic.true)
						: t((l) => l.measures.unit.isBasic.false),
					key: `${unit.id}`,
				}))
			);
		}
	}, [currentMeasureGroup]);

	useEffect(() => {
		getData();
	}, [transactionRollback]);

	if (error)
		return (
			<Result
				title={t((l) => l.measures.error.gettingMeasurmentUnitsError)}
				subTitle={`${t((l) => l.common.error.message)}: ${JSON.stringify(error)}`}
			/>
		);

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={12}>
				<SearchMeasureUnit />
				<Flex gap={6}>
					<CreateMeasureUnit />
				</Flex>
			</Flex>
			<WorkTable
				dataSource={dataSource}
				columns={columns}
				loading={loading || isSearchLoading}
				noFilters
				rowSelection={rowSelection}
				style={{ marginBottom: 65 }}
				pagination={{
					showTotal: (total) => `${t((l) => l.common.tables.total)}: ${total}`,
					pageSizeOptions: [10, 20, 30],
					showSizeChanger: true,
					size: 'small',
				}}
				onRow={(row) => {
					return {
						onClick: () => {
							navigate(`unit/${row.id}`);
						},
					};
				}}
				scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
			/>
			<DetailFooter customHandleBack={customCallback}>
				<DeleteMeasureUnit unitId={selectedRows?.map((item) => item.id)} />
			</DetailFooter>
		</Flex>
	);
};

export const DetailMeasuresGroup = React.memo(DetailMeasuresGroupWidget);
