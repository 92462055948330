import { Flex, Result, Skeleton, Typography } from 'antd';
import React from 'react';
import { DeleteMeasuresGroup } from 'features/metadata/Measures/deleteMeasureGroup';
import { MeasuresGroupEditor } from 'features/metadata/Measures/measuresGroupEditor';
import {
	SearchMeasureGroup,
	useSearchMeasureGroup,
} from 'features/metadata/Measures/searchMeasureGroup';
import { useGetMeasuresGroup } from 'entities/metadata/measures';
import { isEmpty, truncateString } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { DetailCard, Hint, Placeholder } from 'shared/ui';

const MeasuresUnitsWidget: React.FC = () => {
	const { t } = useTypedTranslation();
	const { loading, error, measuresList } = useGetMeasuresGroup();
	const { isLoading: isSearchLoading, searchValue } = useSearchMeasureGroup();

	if (error)
		return (
			<Result
				title={t((l) => l.measures.error.gettingMeasurmentUnitsError)}
				subTitle={`${t((l) => l.common.error.message)}: ${error}`}
			/>
		);

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={12}>
				<SearchMeasureGroup />
				<Flex gap={6} justify="space-between">
					<MeasuresGroupEditor />
				</Flex>
			</Flex>
			<Flex vertical gap={24}>
				<Flex vertical gap={12}>
					<Typography.Title level={2}>
						{t((l) => l.measures.group.groupsTitle)}
					</Typography.Title>
					<Flex gap={12} wrap="wrap">
						{loading || isSearchLoading ? (
							<>
								<Skeleton
									avatar
									active
									paragraph={{ rows: 3 }}
									style={{ maxWidth: 325 }}
								/>
								<Skeleton
									avatar
									active
									paragraph={{ rows: 3 }}
									style={{ maxWidth: 325 }}
								/>
								<Skeleton
									avatar
									active
									paragraph={{ rows: 3 }}
									style={{ maxWidth: 325 }}
								/>
							</>
						) : measuresList && measuresList.length > 0 ? (
							measuresList.map((measure) => (
								<DetailCard
									link={`${measure.id}`}
									tooltip={measure.description && measure.description}
									key={measure.id}
									secondSection={
										<Flex vertical gap={4}>
											<Typography.Title level={3}>
												<Hint
													title={
														measure?.displayName &&
														measure?.displayName?.length > 25
															? `${measure.displayName}`
															: ''
													}
												>
													{measure?.displayName
														? truncateString(measure?.displayName, 25)
														: '-'}
												</Hint>
											</Typography.Title>

											<Typography.Text>
												{`${t((l) => l.measures.unit.basicUnit)}: ${
													!isEmpty(measure.childrenMeasures)
														? truncateString(
																measure.childrenMeasures.find(
																	(item) => item.isDefault
																)?.displayName || '-',
																25
															)
														: '-'
												}`}
											</Typography.Text>
										</Flex>
									}
									dropdownButtons={[
										{
											label: <MeasuresGroupEditor measure={measure} />,
											key: 0,
										},
										{
											label: (
												<DeleteMeasuresGroup measure={measure} isDropdown />
											),
											key: 1,
										},
									]}
								/>
							))
						) : (
							<Flex style={{ width: '100%' }} justify="center">
								<Placeholder
									title={
										searchValue
											? t((l) => l.common.search.nothingFound)
											: t((l) => l.measures.noGroups)
									}
									subTitle={
										searchValue
											? t((l) => l.common.search.checkRequest)
											: t((l) => l.measures.createNewGroup)
									}
									isSearchIcon={!!searchValue}
								/>
							</Flex>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export const MeasureWidget = React.memo(MeasuresUnitsWidget);
