import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import {
	deleteCatalogById,
	updateCatalogGroup,
} from 'entities/catalogs/catalogGroups/catalog.store';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogsMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { findCatalogById } from 'shared/helpers/findCatalogById';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useDeleteCatalog = () => {
	const { notification } = App.useApp();
	const { getTransactions } = useTransactions();
	const [fetchDeleteCatalog, { error, isLoading }] = useDeleteCatalogsMutation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { catalogGroupId } = useParams();
	const { catalogGroups: catalogs } = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups
	);

	const deleteCatalog = async (id: string) => {
		const deletingCatalog = findCatalogById(catalogs as DisplayTreeNode[], id);

		const catalogId = (): string => {
			if (catalogGroupId === id) return deletingCatalog.parentId || '';
			if (findCatalogById([deletingCatalog], catalogGroupId) !== null) return '';
			return catalogGroupId || '';
		};

		fetchDeleteCatalog({ ids: [id] })
			.unwrap()
			.then(() => {
				dispatch(deleteCatalogById(id));
				navigate(`/${routes.catalogs.main}/${catalogId()}`);
				getTransactions();

				if (deletingCatalog.parentId) {
					const parentCatalog = findCatalogById(
						catalogs as DisplayTreeNode[],
						deletingCatalog.parentId
					);
					const children = parentCatalog.children.filter((item) => item.id !== id);
					dispatch(
						updateCatalogGroup({
							...parentCatalog,
							...{
								children: [...children],
								parent: children?.length > 0,
								isLeaf: !(children?.length > 0),
							},
						})
					);
				}
			})
			.catch((err) => errorHelper('Ошибка удаления справочника', err, notification));
	};

	return { loading: isLoading, error, deleteCatalog };
};
