import { RiInformationLine } from '@remixicon/react';
import { Checkbox, Flex, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import { MeasurementResponse } from 'shared/api/generatedApi/mdmgApi';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Hint } from 'shared/ui';
import { useEditMeasureUnit } from './editMeasureUnit.model';

interface IEditMeasureUnitProps {
	unit: MeasurementResponse;
}

const EditMeasureUnitUi: React.FC<IEditMeasureUnitProps> = ({ unit }) => {
	const { t } = useTypedTranslation();
	const { editInput, editBasic } = useEditMeasureUnit();
	const [form] = Form.useForm();

	useEffect(() => {
		if (unit) {
			form.setFieldsValue({
				name: unit?.displayName,
				isBasic: unit?.isDefault,
				code: unit?.shortName,
				factor: unit?.isDefault ? 1 : unit?.coefficient,
				formula: unit?.isDefault ? '—' : unit?.formula,
				revFormula: unit?.isDefault ? '—' : unit?.inverseFormula,
			});
		}
	}, [unit]);

	return (
		<Flex vertical gap={12} style={{ maxWidth: 920, marginBottom: 65 }}>
			<Typography.Title level={2}>{t((l) => l.measures.mainInfo)}</Typography.Title>
			<Form layout="vertical" form={form}>
				<Flex align="center" style={{ marginBottom: 12 }}>
					<Form.Item
						valuePropName="checked"
						name="isBasic"
						style={{ display: 'flex', alignItems: 'center', margin: 0 }}
					>
						<Checkbox onChange={editBasic} checked={unit?.isDefault}>
							{t((l) => l.measures.unit.basicUnit)}
						</Checkbox>
					</Form.Item>
					<Hint placement="right" title={t((l) => l.measures.hint.baseUnit)}>
						<RiInformationLine size={16} color={colors.grayIcon} />
					</Hint>
				</Flex>

				<Form.Item label={t((l) => l.measures.unit.displayName)} name="name">
					<Input allowClear onBlur={editInput} />
				</Form.Item>

				<Form.Item label={t((l) => l.measures.unit.shortName)} name="code">
					<Input allowClear onBlur={editInput} />
				</Form.Item>
				<Form.Item label={t((l) => l.measures.unit.coefficient)} name="factor">
					<Input
						allowClear
						onBlur={editInput}
						disabled={unit?.isDefault || !!unit?.inverseFormula || !!unit?.formula}
						suffix={
							<Hint title={t((l) => l.measures.unit.coefficient)}>
								<RiInformationLine size={16} color={colors.grayIcon} />
							</Hint>
						}
					/>
				</Form.Item>
				{/* Закомментил пока не добавят формулы*/}
				{/* <Form.Item label={t(l=>l.measures.unit.formula)} name="formula">
					<Input
						allowClear
						disabled={unit?.isDefault || !!unit?.coefficient}
						onBlur={editInput}
						suffix={
							<Hint title={t(l=>l.measures.unit.formula)}>
								<RiInformationLine size={16} color={colors.grayIcon} />
							</Hint>
						}
					/>
				</Form.Item> */}

				{/* <Form.Item label={t(l=>l.measures.unit.inverseFormula)} name="revFormula">
					<Input
						allowClear
						onBlur={editInput}
						disabled={unit?.isDefault || !!unit?.coefficient}
						suffix={
							<Hint title={t(l=>l.measures.unit.inverseFormula)}>
								<RiInformationLine size={16} color={colors.grayIcon} />
							</Hint>
						}
					/>
				</Form.Item> */}
			</Form>
		</Flex>
	);
};

export const EditMeasureUnit = React.memo(EditMeasureUnitUi);
