import { Card, Flex, Typography } from 'antd';
import keycloak from 'keycloak';
import type { KeycloakProfile } from 'keycloak-js';
import React from 'react';
import { useTypedTranslation } from 'shared/hooks';

interface UserProfileInfoProps {
    userInfo: KeycloakProfile;
}

export function UserProfileInfo({ userInfo }: UserProfileInfoProps) {
    const { t } = useTypedTranslation();

    return (
        <Card title={t(l => l.profile.title)}>
            <Flex vertical gap={16}>
                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.login)}</Typography.Text>
                    <Typography.Text strong>{userInfo.username}</Typography.Text>
                </Flex>

                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.firstName)}</Typography.Text>
                    <Typography.Text strong>{userInfo.firstName}</Typography.Text>
                </Flex>

                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.lastName)}</Typography.Text>
                    <Typography.Text strong>{userInfo.lastName}</Typography.Text>
                </Flex>

                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.email)}</Typography.Text>
                    <Typography.Text strong>{userInfo.email}</Typography.Text>
                </Flex>

                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.userId)}</Typography.Text>
                    <Typography.Text strong>{userInfo.id}</Typography.Text>
                </Flex>

                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.twoFactor)}</Typography.Text>
                    <Typography.Text strong>
                        {userInfo.totp ? t(l => l.profile.enabled) : t(l => l.profile.disabled)}
                    </Typography.Text>
                </Flex>

                <Flex vertical>
                    <Typography.Text type="secondary">{t(l => l.profile.roles)}</Typography.Text>
                    <Flex gap={8} wrap="wrap">
                        {keycloak.tokenParsed?.realm_access?.roles.map((role: string) => (
                            <Typography.Text key={role} strong>
                                {role}
                            </Typography.Text>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
}
