import { Flex } from 'antd';
import keycloak from 'keycloak';
import type { KeycloakProfile } from 'keycloak-js';
import React, { useEffect, useState } from 'react';
import { UserProfileDebugInfo } from 'widgets/UserProfile/UserProfileDebugInfo';
import { UserProfileInfo } from 'widgets/UserProfile/UserProfileInfo';

export function UserProfilePage() {
    const [userInfo, setUserInfo] = useState<KeycloakProfile>({} as KeycloakProfile);

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserProfile()
                .then((profile) => {
                    setUserInfo(profile);
                })
                .catch((error) => {
                    console.error('Failed to load user profile:', error);
                });
        }
    }, []);

    return (
        <Flex vertical gap={16} style={{ margin: '24px auto', maxWidth: 800 }}>
            <UserProfileInfo userInfo={userInfo} />
            <UserProfileDebugInfo userInfo={userInfo} />
        </Flex>
    );
};
