export enum TaskType {
	/** Фоновый процесс обновления каталога */
	UPDATE_CATALOG_BACKGROUND_TASK = 'UPDATE_CATALOG_BACKGROUND_TASK',

	/** Фоновый процесс экспорта каталога */
	EXPORT_BACKGROUND_TASK = 'EXPORT_BACKGROUND_TASK',

	/** Фоновый процесс импорта из файла */
	IMPORT_BACKGROUND_TASK = 'IMPORT_BACKGROUND_TASK',

	/** Фоновый процесс удаления атрибута */
	DELETE_ATTRIBUTES_BACKGROUND_TASK = 'DELETE_ATTRIBUTES_BACKGROUND_TASK',

	/** Фоновый процесс удаления группы атрибутов */
	DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK = 'DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK',

	/** Фоновый процесс удаления каталога */
	DELETE_CATALOGS_BACKGROUND_TASK = 'DELETE_CATALOGS_BACKGROUND_TASK',

	/** Фоновый процесс добавления декларации на каталог */
	CREATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK = 'CREATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK',

	/** Фоновый процесс обновления единицы измерения в декларации атрибута на каталог или классификатор */
	UPDATE_MEASUREMENT_ON_DECLARATION_BACKGROUND_TASK = 'UPDATE_MEASUREMENT_ON_DECLARATION_BACKGROUND_TASK',

	/** Фоновый процесс удаления декларации из каталога */
	DELETE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK = 'DELETE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK',

	/** Фоновый процесс удаления классификатора */
	DELETE_CLASSIFIER_BACKGROUND_TASK = 'DELETE_CLASSIFIER_BACKGROUND_TASK',

	/** Фоновый процесс обновления классификатора */
	UPDATE_CLASSIFIER_BACKGROUND_TASK = 'UPDATE_CLASSIFIER_BACKGROUND_TASK',

	/** Фоновый процесс добавления декларации на классификатор */
	CREATE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK = 'CREATE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK',

	/** Фоновый процесс обновления коэффициента единицы измерения */
	UPDATE_MEASUREMENT_BACKGROUND_TASK = 'UPDATE_MEASUREMENT_BACKGROUND_TASK',

	/** Фоновый процесс удаления декларации из классификатора */
	DELETE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK = 'DELETE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK',

	/** Фоновый процесс поиска дубликатов */
	DEDUPLICATION_BACKGROUND_TASK = 'DEDUPLICATION_BACKGROUND_TASK',

	/** Публикация транзакции */
	TRANSACTION_COMMIT = 'TRANSACTION_COMMIT',

	/** Пуш уведомление */
	PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}